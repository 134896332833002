import {
  Controller
} from '@hotwired/stimulus'

class ComponentController extends Controller {
  declare readonly element: HTMLElement;
  static targets = [];
  
  connect() {
    const head = document.querySelector("head");
    this.script = document.createElement("script");
    this.script.src = "https://analytics.godliauskas.lt/js/script.outbound-links.js";
    this.script.dataset["domain"] = "godliauskas.lt";
    this.script.defer = true;

    head.appendChild(this.script)
  }

  disconnect() {
    const head = document.querySelector("head");
    if (head && this.script) {
      head.removeChild(this.script);
    }
  }
}


export default ComponentController;
