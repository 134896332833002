import {
  Controller
} from '@hotwired/stimulus'

class ComponentController extends Controller {
  declare readonly element: HTMLElement;
  static targets = [];
  
  connect() {
    const noiseTemplate = document.querySelector("#noise-template img");
    if (noiseTemplate && noiseTemplate.src) {
      document.body.style.backgroundImage = `url(${noiseTemplate.src})`;
    }
  }

  disconnect() {
  }
}


export default ComponentController;
