import {
  Controller
} from '@hotwired/stimulus'

console.log('[project user resource form] init');

export default class extends Controller {
  static get targets() {
    return [];
  }

  connect() {}

  send(e) {
    e.detail[0].setRequestHeader('Authorization', localStorage.getItem('project-user-resource-form-token'));
  }

  success(e) {
    const authToken = e.detail[2].getResponseHeader('Authorization');
    if (authToken) {
      localStorage.setItem('project-user-resource-form-token', authToken);
    }

    const successUrl = this.data.get('successUrl');
    if (successUrl) {
      Turbolinks.visit(successUrl);
    }
  }

  error(e) {
    alert(e.detail[0]);
  }

  disconnect() {}
}
