import {
  Controller
} from '@hotwired/stimulus'

class ComponentController extends Controller {
  declare readonly element: HTMLElement;
  static targets = [];
  ticking: boolean;

  changeColor(document) {
    const COLORS = ["#FBE8DE", "#F8E9CA", "#FBFFFF", "#ECFFF7", "#C4D4C6"];

    const { documentElement } = document;
    const scrollY = document.documentElement.scrollTop;
    const maxHeight = documentElement.scrollHeight - window.innerHeight;
    const percent = scrollY / maxHeight;
    const index = Math.min(
      Math.floor(percent * COLORS.length),
      COLORS.length - 1
    );
    const percentInColor = ((percent - (index / COLORS.length))*COLORS.length *100);
    
    const color1 = COLORS[index];
    const color2 = COLORS[Math.min(index + 1, COLORS.length - 1)];
    const color = this.transitionColor(color1, color2, percentInColor);

    document.body.style.backgroundColor = color;
  }

  connect() {
    this.ticking = false;

    this.changeColor(document);

    document.addEventListener("scroll", this.onScroll.bind(this));
  }

  transitionColor(startColor, endColor, percentage) {
    // Parse the start and end colors
    var startRed = parseInt(startColor.slice(1, 3), 16);
    var startGreen = parseInt(startColor.slice(3, 5), 16);
    var startBlue = parseInt(startColor.slice(5, 7), 16);
    var endRed = parseInt(endColor.slice(1, 3), 16);
    var endGreen = parseInt(endColor.slice(3, 5), 16);
    var endBlue = parseInt(endColor.slice(5, 7), 16);

    // Calculate the intermediate color
    var red = Math.round(startRed + (endRed - startRed) * percentage / 100);
    var green = Math.round(startGreen + (endGreen - startGreen) * percentage / 100);
    var blue = Math.round(startBlue + (endBlue - startBlue) * percentage / 100);

    // Convert the intermediate color to hexadecimal format
    var hex = '#' + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1);

    // Return the intermediate color
    return hex;
  }

  onScroll(e) {
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.changeColor(document);
        this.ticking = false;
      });

      this.ticking = true;
    }
  }

  disconnect() {
    document.removeEventListener("scroll", this.onScroll);
  }
}


export default ComponentController;
