console.log('[core.js]');

require('@rails/ujs').start();

console.log('[core.js] import stimulus');

import {
  Application
} from '@hotwired/stimulus'
// import {
//   definitionsFromContext
// } from '@hotwired/stimulus-webpack-helpers'
import {
  definitions
} from 'stimulus:./controllers';

console.log('[core.js] start application');
window.Stimulus = Application.start()
// console.log('[core.js] require context');
// const context = require.context("./controllers", true, /\.js$/)
console.log('[core.js] load stimulus controllers');
// Stimulus.load(definitionsFromContext(context))
Stimulus.load(definitions);

import "../css/core.scss"
import 'modern-normalize';
