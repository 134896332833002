import c0 from './background_color_scroll_controller';
import c1 from './form_controller';
import c2 from './full_page_noise_controller';
import c3 from './insert_analytics_cv_website_controller';
import c4 from './product_form_controller';
import c5 from './project_user_resource_form_controller';
import c6 from './project_user_signin_form_controller';
export const definitions = [
	{identifier: 'background-color-scroll', controllerConstructor: c0},
	{identifier: 'form', controllerConstructor: c1},
	{identifier: 'full-page-noise', controllerConstructor: c2},
	{identifier: 'insert-analytics-cv-website', controllerConstructor: c3},
	{identifier: 'product-form', controllerConstructor: c4},
	{identifier: 'project-user-resource-form', controllerConstructor: c5},
	{identifier: 'project-user-signin-form', controllerConstructor: c6},
];
